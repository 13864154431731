.apps-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-column-gap: 30px;

    .application {
        max-width: 360px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .title {
            margin-bottom: 20px;
            color: #343C6A;
        }

        .app-logo {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        .download-title {
            text-align: center;
            margin-bottom: 10px;
            color: #343C6A;
        }

        .links {
            display: flex;
            gap: 5px;
            flex-direction: column;
            justify-content: center;

            a {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid #0000002b;
                background: #fff;
                color: #000;
                width: 130px;
                height: 32px;
                padding: 7px 9px;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 5px;
                font-weight: 600;

                &:hover {
                    transition: all .2s;
                    background: rgb(0 0 0 / 4%);
                    color: #343C6A;
                }
            }
        }
    }
}

.list {
    margin-left: 30px;
}

.modal-title {
    color: #343C6A;
    margin: 10px 0;
}

@media screen and (max-width:840px) {
    .apps-links {
        grid-template-columns: repeat(1, 1fr);
    }
}