.page-content {
    margin: auto;

    .cards-container {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 40px;

        .card-style {
            padding: 20px 10px;
            border-bottom: 4px solid;
            border-radius: 14px;
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            height: 120px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            color: #718EBF;
            font-size: 15px;
            font-weight: 400;

            .texts {
                display: flex;
                gap: 5px;
                flex-direction: column;
                justify-content: space-between;

                span {
                    color: #343C6A;
                    font-size: 17px;
                    font-weight: 600;
                }
            }
        }
    }

    .charts-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .chart {
        border-radius: 10px;
        padding: 10px 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .title {
            margin-bottom: 30px;
            color: #343C6A;
        }

        .pie-chart {
            display: grid;
            align-items: center;
        }
    }
}

.info-icon {
    color: blue;
    font-size: 20px;
    cursor: pointer;
}

.platforms-statistics {
    .platform {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: grey;

        .model-logo {
            display: flex;
            text-transform: capitalize;
            text-align: center;

            img {
                width: 90px;
                height: 45px;
                object-fit: cover;
                margin-right: 10px;
            }

            .model-count {
                display: flex;
                align-items: center;
            }
        }

        .statistic-percent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            width: 150px;

            .statistic {
                position: relative;
                width: 100px;
                height: 8px;

                .progress {
                    border-radius: 5px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: .2;
                }

                .percent {
                    border-radius: 5px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .page-content {

        .cards-container {
            display: grid;
            gap: 15px;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 20px;
        }

        .charts-container {
            display: block;

            .chart {
                margin-bottom: 25px;
            }
        }
    }
}

@media screen and (max-width:500px) {
    .page-content {

        .cards-container {
            grid-template-columns: 1fr;

        }

        .chart {
            margin-bottom: 25px;
        }
    }
}

@media screen and (max-width:350px) {
    .platforms-statistics {
        .platform {
            .model-logo {
                img {
                    width: 60px;
                }
            }

            .statistic-percent {
                justify-content: flex-end;

                .statistic {
                    width: 80px;
                }
            }
        }
    }
}