.sider-bottom {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 10px;

    .note {
        display: flex;
        align-items: center;
        gap: 8px;

        a {
            svg {

                width: 31px;
            }
        }
    }

    .logout-button {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        background: #c66456;
        width: 100%;
        height: 2.6rem;
        justify-content: center;

        .logout-icon {
            svg {
                width: 22px;
                height: 22px;
                font-weight: 700;
            }
        }
    }
}

.menu-item-profile {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    background: #2b7b64;
    width: 100%;
    height: 2.6rem;
    justify-content: center;
    padding-left: 0;
    .name {
        display: block;
        white-space: nowrap;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &.active {
        background-color: #2b7b64;
        border-radius: 10px;
    }
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}