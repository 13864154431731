@font-face {
  font-family: "cairo-extrabold";
  src: url("./Cairo/Cairo-ExtraBold.otf");
  font-display: block;
}
@font-face {
  font-family: "cairo-bold";
  src: url("./Cairo/Cairo-Bold.otf");
  font-display: block;
}

@font-face {
  font-family: "cairo-semibold";
  src: url("./Cairo/Cairo-SemiBold.otf");
  font-display: block;
}

@font-face {
  font-family: "cairo-medium";
  src: url("./Cairo/Cairo-Medium.otf");
  font-display: block;
}

@font-face {
  font-family: "cairo-reqular";
  src: url("./Cairo/Cairo-Regular.otf");
  font-display: block;
}
@font-face {
  font-family: "cairo";
  src: url("./Cairo/Cairo-VariableFont_slnt\,wght.otf");
  font-display: block;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.span {
  font-family: cairo-reqular !important;
}
a {
  font-family: cairo-reqular !important;
}
b {
  font-family: cairo-reqular !important;
}
p {
  font-family: cairo-reqular !important;
}
strong {
  font-family: cairo-reqular !important;
}
li {
  font-family: cairo-reqular !important;
}
.ant-drawer-title {
  font-family: 'cairo-reqular';
}

h1, h2, h3, h4 {
  font-family: cairo-reqular !important;
}