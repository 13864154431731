.link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000000;

    span {
        color: #878a99;
    }
}

.header-refresh-button {
    display: flex;
    align-items: center;
    gap: 5px;

    .refresh-icon {
        width: 20px;
        height: 20px;
        position: relative;

        svg {
            width: 100%;
            height: 100%;

            position: absolute;
            left: 0;
            top: 0;

            transition: transform .3s;
        }

        .check-icon {
            svg {
                transform: scale(0);
            }
        }
    }

    &.click {
        cursor: not-allowed;

        .reload-icon {
            svg {
                animation: loading 1s linear infinite;
            }
        }
    }

    &.done {
        cursor: not-allowed;

        .reload-icon {
            svg {
                transform: scale(0);
            }
        }

        .check-icon {
            svg {
                transform: scale(1);
            }
        }
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}