.export-modal-content {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .buttons {
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}