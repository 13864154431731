* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
aside.ant-layout-sider.ant-layout-sider-dark {
  overflow: auto;
  position: relative;
  left: 0px;
  top: 0px;
  bottom: 0px;
  transition: 0.2s;
  transform: translateX(0px);
  z-index: 0;
  flex: 0 0 220px!important;
  max-width: 220px!important;
  min-width: 220px!important;
  width: 220px!important;
  overflow-x: hidden !important;
  z-index: 100 !important;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
  z-index: 100;
}
button.ant-btn.css-dev-only-do-not-override-1sncj2c.ant-btn-text.ant-btn-icon-only {
  width: 60px!important;
}

.info-icon {
  color: blue;
  font-size: 18px;
  cursor: pointer;
}

.form-btn-container {
  margin: auto;
  width: max-content;
}
li.ant-menu-item {
  width: 100% !important;
  background: #ffffff0d;
  border-radius: 0 !important;
}

.drawer-title {
  font-size: 20px;
  font-weight: 600;
  color: grey;
}

@media screen and (max-width: 900px) {
  header.ant-layout-header {
    left: 0 !important;
  }
  .ant-picker-panels {
    flex-direction: column !important;
  }

  .ant-layout-sider {
    max-width: 300px !important;
    width: 300px !important;
  }
}

.apexcharts-menu-icon {
  display: none;
}

.ant-layout-sider {
  background-image: url("./assets/illustrations/sidebar.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.demo-logo {
  height: 38px;
  margin: 23px auto;
  cursor: pointer;
}

.ant-menu-item {
  color: #FFF !important;
  font-size: 16px;
  font-weight: 600;
}

.ant-btn-primary {
  background-color: #429229 !important;

  &:disabled {
    background: #d8d8d859 !important;
  }
}

.ant-btn-default {
  color: #429229 !important;

  &:hover {
    border-color: #429229 !important;
  }
}

:where(.css-dev-only-do-not-override-k81288).ant-btn-link {
  color: #429229 !important;

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

:where(.css-dev-only-do-not-override-k81288).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FFF !important;

}



//language styles start

.lang-dropdown {
  cursor: pointer;
}

.lang-dropdown-overlay {
  cursor: pointer;

  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    padding: 0 3px;
  }
}


// language styles end